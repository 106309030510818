import React from 'react';
import Modal from 'react-awesome-modal';
import ContentBlock from 'components/content-block';

import './LeavingInterstitial.scss';

export const LeavingInterstitial = ({
  open,
  handleContinueClick,
  handleCancelClick,
}) => {
  return (
    <div className="interstitial-modal">
      <Modal visible={open} onClickAway={() => handleCancelClick()}>
        <ContentBlock>
          <div className="modal-container">
            <div className="modal-title">
              <h2>YOU ARE NOW LEAVING SPRIX.COM/PATIENT</h2>
            </div>
            <div className="modal-body">
              <p>
                The link you clicked on will take you to a site maintained by a
                third party, which is solely responsible for its content.
                Assertio is not responsible for the privacy policy of any
                third-party websites. We encourage you to read the privacy
                policy of every website you visit.
              </p>
            </div>
            <div className="modal-actions">
              <button onClick={handleContinueClick}>Continue</button>
              <button onClick={handleCancelClick}>Cancel</button>
            </div>
          </div>
        </ContentBlock>
      </Modal>
    </div>
  );
};
