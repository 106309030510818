import React, { useState, useEffect } from 'react';

import { Layout, Seo } from 'components';
import { CommonContainer } from 'components/common-container';
import ContentBlock from 'components/content-block';
import ButtonSprix from 'components/button-sprix';
import { LeavingInterstitial } from 'components/modals/LeavingInterstitial';

import arrow from 'images/patient/pages/get-sprix-now/button-arrow.png';
import threeImages from 'images/patient/pages/get-sprix-now/patient-cove-three-images.png';

import './get-sprix-now.scss';

const pageTitle = 'Get SPRIX Now';
const pageDescription = '';

const GetSprixNowPage = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleContinueClick = () => {
    const link =
      'https://get.withcove.com/sprix?utm_source=assertio_site&utm_medium=organic&utm_campaign=sprix_telemedicine';
    setModalOpen(false);
    typeof window !== 'undefined' && window.open(link, '_blank');
  };

  const handleCancelClick = () => {
    setModalOpen(false);
  };

  const handleBtnClick = e => {
    e.persist();
    setModalOpen(true);
  };
  const rederStep1 = () => {
    return (
      <div className="step-section">
        <div className="round-container">1</div>
        <div className="center-align steps-para">
          Sign up at <br />{' '}
          <span className="dark-color button-link" onClick={handleBtnClick}>
            WithCove.com
          </span>
        </div>
      </div>
    );
  };
  const rederStep2 = () => {
    return (
      <div className="step-section step-section-middle">
        <div className="top-section center">
          <div className="round-container">2</div>
          <div className="center-align steps-para">
            Complete an online <br /> consultation with an <br />
            independent, <br />
            licensed physician
          </div>
        </div>
        <div className="bottom-section">
          <div className="button-link" onClick={handleBtnClick}>
            <ButtonSprix patient>
              Get Started with Cove
              <img className="arrow" src={arrow} />
            </ButtonSprix>
          </div>
        </div>
      </div>
    );
  };

  const rederStep3 = () => {
    return (
      <div className="step-section">
        <div className="round-container">3</div>
        <div className="center-align steps-para">
          Receive a personalized
          <br /> treatment plan for your needs,
          <br /> and your medicine will be
          <br /> delivered to your home{' '}
        </div>
      </div>
    );
  };

  const render3Steps = () => {
    return (
      <div className="three-steps-container">
        {rederStep1()}
        {rederStep2()}
        {rederStep3()}
      </div>
    );
  };
  return (
    <Layout indication="patient" className="patient-get-sprix-now">
      <Seo pageTitle="Patient About Page" pageTitle={pageTitle} />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <ContentBlock>
            <h2 className="blue-headline no-upper-case">
              <strong>GETTING SPRIX&reg; (KETOROLAC TROMETHAMINE)</strong>
            </h2>
            <h2 className="blue-headline no-margin-top">
              MAY BE SIMPLER THAN YOU THINK
            </h2>
            <p className="copy-block">
              SPRIX is now available through{' '}
              <span className="dark-color button-link" onClick={handleBtnClick}>
                Cove
              </span>
              , a leading telemedicine company. Cove will pair you with an
              independent, licensed healthcare provider who will work with you
              to develop an appropriate treatment plan. This program is for
              cash-paying patients only.
            </p>
          </ContentBlock>
          <div className="connect-with-cove">
            <ContentBlock>
              <h2 className="no-margin-top bold-title">
                Connect with Cove in 3 simple steps:
              </h2>
              {render3Steps()}
            </ContentBlock>
          </div>
          <ContentBlock>
            <p className="copy-block">
              Cove is available to most people in the US (roughly 90% of the
              population).
              <br /> Telemedicine regulations vary from state to state.{' '}
              <span className="dark-color button-link" onClick={handleBtnClick}>
                Click here
              </span>{' '}
              to see if Cove is
              <br /> available where you live.
            </p>
            <p className="center-align img-width">
              <img src={threeImages} className="img-width" alt="Cove Sprix" />
            </p>
          </ContentBlock>
        </div>
        {modalOpen && (
          <LeavingInterstitial
            open={modalOpen}
            handleContinueClick={handleContinueClick}
            handleCancelClick={handleCancelClick}
          />
        )}
      </div>
    </Layout>
  );
};

export default GetSprixNowPage;
