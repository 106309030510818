import React from 'react';
import cx from 'classnames';

import './index.scss';

const ButtonSprix = ({
  className,
  secondary,
  light,
  big,
  twoLine,
  patient,
  ...otherProps
}) => (
  <div className="button-container">
    <span
      role="button"
      {...otherProps}
      className={cx({
        [className]: className,
        ['button']: true,
        ['secondary']: secondary,
        ['light']: light,
        ['big']: big,
        ['two-line']: twoLine,
        ['patient']: patient,
      })}
    ></span>
  </div>
);

export default ButtonSprix;
